import { DBFactory } from "~/classes/database/db_factory";
import { BaseModel } from "../base.model";

type ClassroomEmailInfoConstructorParams = {
  id?: string;
  userId: string;

  recipients: {
    studentId?: string;
    label?: string;
    email: string;
    token?: string;
    hasRead: boolean;
    viewCount?: number;
    lastViewedAtTimestamp?: number;
  }[];
};

export class ClassroomEmailInfo extends BaseModel {
  id?: string;
  userId: string;
  recipients: {
    studentId?: string;
    label?: string;
    email: string;
    token?: string;
    hasRead: boolean;
    viewCount?: number;
    lastViewedAtTimestamp?: number;
  }[];

  constructor(data: ClassroomEmailInfoConstructorParams) {
    super(data);

    this.id = data.id;
    this.userId = data.userId;
    this.recipients = data.recipients;
  }

  get databaseConfig(): ModelDatabaseConfig {
    return {
      collection: `/classroomEmails/${this.id}/info/${this.id}`,
      path: `/classroomEmails/${this.id}/info/${this.id}`,
    };
  }

  get numRecipients() {
    return this.recipients.length;
  }

  get numOpened() {
    return this.recipients.filter((r) => r.hasRead).length;
  }

  get numUnopened() {
    return this.recipients.filter((r) => !r.hasRead).length;
  }

  get openRate() {
    return this.numOpened / this.numRecipients;
  }

  static fromMap(map: any) {
    return new ClassroomEmailInfo(map);
  }

  save() {
    const db = DBFactory.createDatabase();
    db.save(this.toMap(), this.databaseConfig);
  }

  delete() {
    const db = DBFactory.createDatabase();
    db.delete(this.databaseConfig);
  }
}
