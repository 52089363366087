import { DBFactory } from "~/classes/database/db_factory";
import { BaseModel } from "../base.model";
import {
  EducationResource,
  EducationResources,
} from "../education-resources/education-resource.model";
import { ClassroomEmailInfo } from "./classroom-email-info.model";

export type ClassroomEmailConstructorParams = {
  id?: string;
  userId: string;
  label: string;
  displayName: string;
  subject: string;
  body: string;
  info?: ClassroomEmailInfo;
  attachments: EducationResources;
  createdAtTimestamp: number;
  sentAtTimestamp?: number;
  classroomId?: string;
  isArchived: boolean;
};

export type ClassroomEmails = ClassroomEmail[];

export class ClassroomEmail extends BaseModel {
  id?: string;
  userId: string;
  label: string;
  displayName: string;
  subject: string;
  body: any;
  attachments: EducationResources;
  createdAtTimestamp: number;
  sentAtTimestamp?: number;
  info?: ClassroomEmailInfo;
  classroomId?: string;
  isArchived: boolean;

  constructor(data: ClassroomEmailConstructorParams) {
    super(data);

    this.id = data.id;
    this.label = data.label;
    this.displayName = data.displayName;
    this.userId = data.userId;
    this.subject = data.subject;
    this.body = data.body;
    this.attachments = data.attachments;
    this.createdAtTimestamp = data.createdAtTimestamp;
    this.sentAtTimestamp = data.sentAtTimestamp;
    this.info = data.info;
    this.classroomId = data.classroomId;
    this.isArchived = data.isArchived ?? false;
  }

  get databaseConfig(): ModelDatabaseConfig {
    return {
      collection: "/classroomEmails",
      path: `/classroomEmails/${this.id}`,
    };
  }

  static fromMap(map: any) {
    if (map["info"]) {
      map["info"] = ClassroomEmailInfo.fromMap(map["info"]);
    }

    if (map["attachments"]) {
      map["attachments"] = map["attachments"].map((attachment: any) =>
        EducationResource.fromMap(attachment)
      );
    }

    return new ClassroomEmail(map);
  }

  async sendEmail() {
    await useFetch(`/api/lms/emails/${this.id}/send`, {
      method: "POST",
      headers: await useApiHeaders(),
    });
  }

  toMap(): ModelDatabaseData {
    return {
      id: this.id,
      label: this.label,
      userId: this.userId,
      subject: this.subject,
      displayName: this.displayName,
      body: this.body,
      attachments: this.attachments.map((attachment) => attachment.toMap()),
      createdAtTimestamp: this.createdAtTimestamp,
      sentAtTimestamp: this.sentAtTimestamp,
      classroomId: this.classroomId,
      isArchived: this.isArchived,
    };
  }
  async markAsViewed(token: string) {
    await useFetch(`/api/lms/emails/${this.id}/${token}/viewed`, {
      method: "POST",
    });
  }

  async save() {
    const db = DBFactory.createDatabase();
    this.id = await db.save(this.toMap(), this.databaseConfig);

    if (this.info) {
      this.info.id = this.id;
      this.info.pathPrefixOverride = this.pathPrefixOverride
      this.info.save();
    }
  }

  async delete() {
    this.isArchived = true;
    await this.save();
  }
}
